 const loginUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/token';
 const logoutUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/revoke';
 const userInforUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/my/user_info';

 const baseUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/standard/media';
// export const client_id = "t7usWb-2mYbqg-7t23Zqbcc7v6Q2JODmD7WE9juCj8g";
// export const client_secret = "BMmtx9gZahvrETIVhXBvr3Us_8-8fGspfssE3Cr7dqM";
 const create_mediaUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/standard/media';

 const dashboard_mediaUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/standard/dashboard/index';
 const s3_endpointUrl = 'https://s3.us-east-1.wasabisys.com';


export const Endpoints = {
    loginUrl,
    logoutUrl,
    dashboard_mediaUrl,
    userInforUrl,
    baseUrl,
    create_mediaUrl,
    s3_endpointUrl,
};