import React, { useState, useRef, useEffect,useCallback } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import axios from 'axios';


const DropdownEditMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const authHeader = useAuthHeader();
  const [nameValue, setNameValue] = useState(props.name);
  const [descriptionValue, setdescriptionValue] = useState(props.description);
  
  const [isAbove, setIsAbove] = useState(false);
  const dropdownRef = useRef(null);

  const [disabled, setDisabled] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [showBlur, setShowBlur] = useState(false);
  
 
  const handleNameInputChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleDescriptionInputChange = (event) => {
    setdescriptionValue(event.target.value);
  };
  
  const checkPosition = useCallback(() => {
    if (btnRef.current && dropdownRef.current) {
      const buttonRect = btnRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Check if dropdown will fit below the button
      if (buttonRect.bottom + dropdownRect.height > viewportHeight) {
        setIsAbove(true);
      } else {
        setIsAbove(false);
      }
    }
  }, [isOpen]);

  const finishEdit = async()=>{
    await editItemInfo()
  }
   
  const cancelEdit = ()=>{
     setShowForm(false);
     setShowBlur(false);
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const showEditForm = async()=>{
    setShowForm(true);
    setShowBlur(true);
  }

  const btnRef = useRef()

  useEffect(() => {
        const closeDropdown = e => {
            if (!btnRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    }, [])

  const editUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/standard/media/' + props.id;
  const header = {
    'Authorization' : authHeader()
  }

  useEffect(() => {
    if (isOpen) {
      checkPosition();
      window.addEventListener('resize', checkPosition);
      return () => window.removeEventListener('resize', checkPosition);
    }
  }, [isOpen, checkPosition]);


  const deleteItem = async()=>{
      setShowBlur(true);
      setDisabled(true);
      const confirm = window.confirm('Are you sure you want to delete this asset?');
      if(!confirm){
        setShowBlur(false);
        setDisabled(false);
        return;
      }
      const res = await axios.delete( editUrl,{headers: {
        'Authorization': authHeader(),
    },}).then(res =>{
      if(res.status === 200){
        console.log('successfully deleted');
        setShowBlur(false);
        setDisabled(false);
        // props.refresh().then(res=>{
        //   console.log(res);
        //   if(res){
        //     setShowBlur(false);
        //     setDisabled(false);
        //   }
        // });
      }
      }); 
  }
 
  const editItemInfo = async()=>{
   await axios.put( editUrl,
      {
        name:nameValue,
        description:descriptionValue
      },
      {headers: {
      'Authorization': authHeader(),
  },}).then(res =>{
    if(res){
      props.refresh().then(res=>{
          setShowForm(false);
          setShowBlur(false);
          setDisabled(false);
      });
    }
    }); 
}



  const menuItems = [
    { id: 0, label: "Edit", href: "#" , action:showEditForm, status: disabled},
    { id: 1, label: "Delete", href: "#",action:deleteItem ,status: disabled},
    { id: 2, label: "Other", href: "#", action:null ,status: disabled}
  ];

  return (
    <div className="relative inline-block">
      <div>
        <button
          ref={btnRef}
          type="button"
          onClick={toggleMenu}
          className="inline-flex w-full text-right border-none gap-x-1.5 bg-transparent pl-1 py-1 text-sm font-semibold text-gray-900"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>
        </button>
      </div>
      { isOpen && (
        <div ref={dropdownRef}
         className={`absolute w-max right-0 z-10 origin-top-right 
          ${
            isAbove ? 'bottom-full mb-2' : 'top-full mt-2'
          }
                    rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            {menuItems.map(item => (
              <button key={item.id} href={item.href} 
                 onClick={item.action}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-slate-100" role="menuitem" tabIndex="-1" id={`menu-item-${item.id}`}>
                {item.label}
              </button>
            ))}
            {/* <form method="POST" action="#" role="none">
              <button type="submit" className="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabIndex="-1" id="menu-item-3">
                Sign out
              </button>
            </form> */}
          </div>
        </div>
      )}
      {showBlur && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" style={{zIndex:'1001'}}>
          <div className='text-white h-full w-full flex flex-col justify-center items-center'>
          <div className='absolute inset-0  flex items-center justify-center z-10'>
                 <svg className="animate-spin h-10 w-10 " viewBox="0 0 24 24">
                  <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                    ></path>
                  </svg>
               </div>
          </div>
        </div>
      )}

        {showBlur && (<div className='w-full h-full justify-center items-center'>
        <svg className="animate-spin h-8 w-8 mr-3 " viewBox="0 0 24 24">
                      <circle
                          className="opacity-55"
                          cx="50%"
                          cy="50%"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        saving changes....
                        <path
                          className="opacity-50"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                        ></path>
                      </svg>
        
          </div>)}

      {showForm && (
        <div className="fixed inset-0 w-screen overflow-y-auto" style={{zIndex:'1010'}}>
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0 ">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
               
                  <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Please fill in the information</h3>
                    <div className="flex flex-col w-full">
                       <div className="p-4 overflow-y-auto">
                       {/* <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                            filename
                        </label> */}
                        <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                          Name
                        </label>
                        <input
                          type="text"
                          maxLength="50"
                          id="input-label"
                          value={nameValue}
                          onChange={handleNameInputChange}
                          className="py-3 px-4 block w-full border border-black ring-black rounded-lg text-sm focus:border-black focus:ring-black dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:text-neutral-400"
                          // placeholder={nameValue}

                        />
                    </div>
                    <div className="p-4 overflow-y-auto">
                        <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                          Description (optional)
                        </label>
                        <textarea
                          type="text"
                          maxLength="200"
                          id="input-label"
                          value={descriptionValue}
                          onChange={handleDescriptionInputChange}
                          className="py-3 px-4 block w-full border border-black rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:text-neutral-400"
                          placeholder="description"
                        />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row w-full justify-end sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-black
                              text-white border border-black hover:bg-black
                                hover:scale-105 transition-transform duration-200 ease-in-out
                                active:scale-95
                               hover:text-white px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mr-2 sm:w-auto"
                  onClick={finishEdit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md
                               hover:scale-105 transition-transform duration-200 ease-in-out
                                active:scale-95
                             bg-white px-3 py-2 text-sm font-semibold border-black hover:bg-black hover:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-black sm:mt-0 sm:w-auto"
                  onClick={cancelEdit}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}




    </div>
  );
};

export default DropdownEditMenu;
