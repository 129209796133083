import { createContext, useContext, useState,useEffect } from "react";
import axios from 'axios';


export const KiivuUploadContext = createContext();

export const KiivuUploadProvider = ({children})=>{
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [fileUploadProcesses, setFileUploadProcess] = useState([]);
  const [progresses, setProgresses] = useState(0);

  // const [fileProgresses, setFileProgresses] = useState([]);

 // const controller = new AbortController();
  
  // console.log(fileUploadProcesses);
  // console.log(progresses);
  const makeConcurrentRequests = async (requests) => {
    try {
      // Map each request configuration to an Axios request
      const promises = requests.map(request => uploadFile(request['url'],request['formData'], request['header']));
  
      // Use Promise.all to wait for all requests to complete
      const responses = await Promise.all(promises);
  
      // Return an array of response data
      console.log(responses);
      return responses.map(response => response.data);
    } catch (error) {
      // Handle errors here
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error making concurrent requests:', error);
        throw error; // Throw the error further to handle it outside
      }
    }
  };


    useEffect(()=>{
      if(fileUploadProcesses.length > 0){
        console.log(fileUploadProcesses);
        const request = fileUploadProcesses[0];
        uploadFile(request['url'],request['formData'], request['header']);
       // makeConcurrentRequests(fileUploadProcesses);
      }
    },[]);
   

   const processUploadQueue= ()=>{
    if(fileUploadProcesses.length === 0) return;
      for(let process in fileUploadProcesses){
        uploadProcess(process);
      }
   }

   const uploadProcess = async () => {
    // setLoading(true);
    // setError(null);
    // setProgress(0);
   
    try {
      const headers = process.header;
     const response = await axios.post(process.url, process.formData, {
      headers,
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            // const pro = {name:percentCompleted};
            setProgresses(percentCompleted);
        },
        });
        console.log(response.status);
        if(response.status === 201)
        {
          setProgresses(100);
          console.log(progresses);
        }
      // setData(response.data);
      return response.data;
    } catch (err) {
      // setError(err);
      throw err;
    } finally {
      // setLoading(false);
    }
  };

   




    const uploadFile = async (url, formData, headers) => {
        // setLoading(true);
        // setError(null);
        // setProgress(0);
       
        try {
        const response = await axios.post(url, formData, {
            headers,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                // const pro = {name:percentCompleted};
                setProgresses(percentCompleted);
            },
            });
            console.log(response.status);
            if(response.status === 201)
            {
              setProgresses(100);
              console.log(progresses);
            }
          // setData(response.data);
          return {
            status: 1,
            response: response.data
          };
        } catch (err) {
          // setError(err);
          throw err;
        } finally {
          // setLoading(false);
        }
      };


      

    
    return(
        <KiivuUploadContext.Provider value={{fileUploadProcesses, setFileUploadProcess, data, loading, error, progresses, uploadFile}}>
           {children}
        </KiivuUploadContext.Provider>
    );
}

export const useKiivuUpload = ()=>{
    return useContext(KiivuUploadContext);
}
