import React, { useEffect, useState } from 'react';
import bg0 from '../assets/bg0.jpeg';
import DropdownEditMenu from './dropdown_edit_menu';
import './video_item.css';
import { useDashboard } from '../context/DashboardContext';
import defaultImage from '../assets/images/kiivu-default-thumbnail.jpg'
// import image0 from '../assets/images/image0.jpg';
// import image1 from '../assets/images/image1.jpg';
// import image2 from '../assets/images/image2.jpg';
// import image3 from '../assets/images/image3.jpg';
// import image4 from '../assets/images/image4.jpg';



const kiivuDefaultThumbnail = '/kiivu-default-thumbnail.jpg';

const VideoItem = (props) => {
  const [uploading, setUploading] = useState(true);

  const [statusText, setStatusText] = useState(null);

  const {dashboardVideos, setDashboardVideos, uploadVideoFile, videoProcesses,fileProgresses} = useDashboard();


  //const progress = 70;
  const limitTextAt = (text, limit)=>{
    if(text === null) return '';
    if(text === '') return '';
    if(text.length <= limit) return text;
    else{
       let temp = text.substring(0, 10) + '...';
       return temp;
    }
  }


  const randomImageNumber =() =>{
    return Math.floor(Math.random() * (4) + 0);
  //  return Math.floor(Math.random() * (7 - 1) + 1);
  }

  // const randomImage = (id)=>{
  //   const index = id % 5;
  //   return images[index];
  // }

  // useEffect(()=>{
    
  // },[props.progress]);
  
  // useEffect(()=>{
  //    imageSource();
  // },[]);
 
  // console.log(props.media);

  // console.log(props.media.name + ':' + props.media.progress);
  
 const fileSizeFromByte = (bytes)=>{
  const bytesToMB = () => (bytes / (1024 * 1024)).toFixed(2);
  const bytesToGB = () => (bytes / (1024 * 1024 * 1024)).toFixed(2);
  const sizeInGB = bytesToGB();
  const sizeInMB = bytesToMB();
  if(sizeInGB >= 1)
    return sizeInGB + ' GB'
  else{
    return sizeInMB + ' MB'
  }
 }


 const timeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diffInSeconds = Math.floor((now - then) / 1000);

  if (diffInSeconds < 60) {
    return 'just now';
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 365) {
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  }

  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
};



  return (
    <div id={props.media.id} className='cursor-pointer relative'>
      <div className="relative aspect-video w-full bg-gray-200 overflow-hidden rounded-2xl hover:opacity-75">
        {/* <img src="https://source.unsplash.com/random/300x200?video" alt="Video 1" className="object-cover w-full h-full" /> */}
         {!fileProgresses[props.media.id] && <img src={props.media.artwork_cdn_url ?? defaultImage} alt="Video 1" className="object-cover w-full h-full"/>}
        {/* <img src='/kiivu-default-thumbnail.jpg' alt=''/>  */}
        {/* <div>{randomImageNumber()}</div> */}
       {/* {(fileProgresses[props.media.id] && <img src='/kiivu-default-thumbnail.jpg' alt=''/> )} */}
       
       {/* {fileProgresses[props.media.id] && ( 
               <div className='absolute inset-x-0 bottom-2 flex items-center justify-center z-10'>
                 <svg className="animate-spin h-5 w-5 " viewBox="0 0 24 24">
                  <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                    ></path>
                  </svg>
               </div>)} */}
       {(fileProgresses[props.media.id]) && fileProgresses[props.media.id].uploading && (
         <div className="absolute inset-0 flex flex-col px-3  items-center justify-center z-20">
            <div className="h-2  relative w-full rounded-full overflow-hidden bg-white">
              <div className="bg-black absolute left-0 top-0 h-full" style={{ width: `${fileProgresses[props.media.id] != null && fileProgresses[props.media.id].progress !== 'undefined' ? fileProgresses[props.media.id].progress:''}%` }}></div>
            </div>
            <p className="text-black text-center mt-2">{ fileProgresses[props.media.id].status ?? ''}...</p>
          </div>
       )}

       {/* (
         <div className="absolute inset-0 flex flex-col px-3  items-center justify-center">
            <div className="h-2  relative w-full rounded-full overflow-hidden bg-white">
              <div className="bg-black absolute left-0 top-0 h-full" style={{ width: '50%' }}></div>
            </div>
            <p className="text-black text-center mt-2">uploading...</p>
          </div>
       ) */}
      </div>
      <div className="mt-1 w-full flex flex-col items-start">
        <div className='w-full flex flex-row justify-between items-center'>
        <p className="text-gray-800 text-left font-bold w-full truncate">{props.media.name}</p> 
        {!fileProgresses[props.media.id] &&  <DropdownEditMenu name={props.media.name} description={props.media.description} className='w-14'  id={props.media.id} refresh={props.refresh}/>}
        </div>
        {/* <p className="text-gray-800 text-left font-bold w-full truncate">{props.media.name}</p> */}
        {/* <p className="text-gray-500 text-left text-sm w-64 truncate">{limitTextAt(props.media.description, 40)}</p> */}
       <div className='w-full flex flex-row justify-between items-center'>
          <p className="text-gray-500 text-left text-sm w-64 truncate">{fileSizeFromByte(props.media.file_size)}</p>
          <p className='text-gray-500 text-right text-sm w-64'>{timeAgo(props.media.created_at)}</p>
       </div>
      </div>
      {/* <div className='absolute top-0 right-0'>
       {!fileProgresses[props.media.id] &&  <DropdownEditMenu name={props.media.name} description={props.media.description} className='w-14'  id={props.media.id} refresh={props.refresh}/>}
      </div> */}
    </div>
  );
}

export default VideoItem;
