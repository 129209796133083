/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import './login.css'
import {useSignIn} from 'react-auth-kit';
import { useFormik,Formik, Form, Field, ErrorMessage } from "formik";
import axios, { AxiosError } from "axios";
import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import {useIsAuthenticated} from 'react-auth-kit';
import AuthRequestBody from '../../config/authcredential';
import SignupForm from '../signUpPage';
// import LoadingSpinner from '../../components/loading_spinner';
import { LocationContext } from '../../context/locationContext';
import { useKiivuAuth } from '../../context/KiivuAuthProvider';
import { Endpoints } from '../../config/endpoints';

const Login = () => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
 // const [isSigningIn, setIsSigningIn] = useState(true);
  
 const {signedIn, setSignIn} = useKiivuAuth();
 const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  const signIn = useSignIn();
  
  document.title = 'KIIVU';

  // const url = 'https://staging-mosaic-103-api-ds-ab946f1a053c.herokuapp.com/chat';
  
  // const test = async()=>{
  //   try{
  //     const res = await axios.post(url, {"message":'message'});
  //     console.log(res.body);
  //   }catch(e){
  //     console.log(e);
  //   }
  // }

  const onSubmit = async(values) => {
    // try{e.preventDefault();}catch(e){console.log(e);}
    // const values = {email:emailRef.values, password:passwordRef.values}
    // const values = {email:'creator01@kiivu.com', password:'password'};
    // console.log("Values: ", values);
    setLoading(true);
    setError("");
    if(values.email === ""){
      setError('Email is empty');
      setLoading(false);
      return;
    }
    if(values.password === ""){
      setError('password is empty');
      setLoading(false);
      return;
    }
    
   // const  = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/token';
    try {
      const response = await axios.post(
        Endpoints.loginUrl,
        AuthRequestBody(values)
      );
      // console.log(response);
      if(response.status !== 200){
        setLoading(false);
        return;
      }
      // console.log(response.data.access_token);
      var userInfo = {};
       try{
        await axios.get(
          Endpoints.userInforUrl,
          {
             headers:{
              'Authorization' :'Bearer ' + response.data.access_token
             }
          }
        ).then(res=> userInfo = res.data);
      
      }catch(e){
        setLoading(false);
        console.log(e.code);
      }
     


      // console.log(response.data);
      // return;
      const signInResult = signIn({
        token: response.data.access_token,
        refreshToken:response.data.refresh_token,
        refreshTokenExpireIn:response.data.expires_in,
        expiresIn: response.data.expires_in,
        tokenType: "Bearer",
        authState: { email: values.email, userInfo: userInfo },
      });
      
      if(signInResult){
        // setSignIn(isAuthenticated());
        navigate("/",{ replace: true });
      }else{
        console.log('not signed in..');
      }
     
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError('Email or Password is incorrect!');
      // throw(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });


  return (
 
    <div className='login-font flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-white'>
    <div className="h-full">
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h1 className="text-4xl text-center font-bold">KIIVU</h1>
        <h2 className="mt-10 text-center text-xl leading-9 tracking-tight text-gray-900">Sign in</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={formik.handleSubmit} className="space-y-6" action="#" method="POST">
          <div className='flex flex-col items-start'>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
            <div className="mt-2 w-full">
              <input id="email" name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange} type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-black sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6  text-gray-900">Password</label>
              <div className="text-sm">
                <a href="#" className="font-semibold text-purple-700 hover:text-indigo-800">Forgot password?</a>
              </div>
            </div>
            <div className="mt-2">
              <input id="password"  name="password"
                        value={formik.password}
                        onChange={formik.handleChange}
                        placeholder="Password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-black sm:text-sm sm:leading-6 bg-white" />
            </div>
          </div>

          <div>
            <button disabled={isLoading} type="submit" 
            className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
             {isLoading ?  (<svg className="animate-spin h-5 w-5 mr-3 " viewBox="0 0 24 24">
                  <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                    ></path>
                  </svg>): <div></div>
                  }
                  {isLoading ? 'Processing...' : 'Sign In'}
              
              </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          <a href="#" className="font-semibold leading-6 text-purple-700 hover:text-indigo-800">Sign Up</a>
        </p>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Login



function test(){
  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //   },
  //   onSubmit,
  // });
  // return (
      //  <div className='logincontainer w-screen h-screen flex items-center justify-center'>
      //  <div className='w-full h-1/2 xl:w-1/3 sm:w-1/2 bg-gray-800 rounded-3xl shadow-md'>
      //   {/* <div className='absolute inset-0 flex justify-center items-center -z-0 backdrop-filter backdrop-blur-sm bg-opacity-10'/> */}
      //    <form onSubmit={formik.handleSubmit} className='w-full h-full flex flex-col justify-around'>
      //         <span className=' text-5xl text-white'>Welcome</span>
      //         <div>
      //           <label>
      //             {/* {'Email: '} */}
      //           <input  
      //           className='w-1/2 rounded-md px-7 py-2'
      //                   name="email"
      //                   value={formik.values.email}
      //                   onChange={formik.handleChange}
      //                   placeholder="Email"
      //                   // clearOnEscape
      //                   size="large"
      //                   type="email" />
      //           </label>
      //           <br/>
      //           <br/>
      //           <label>
      //             {/* {'Password: '} */}
      //           <input  
      //           className='w-1/2 rounded-md px-7 py-2'
      //                   name="password"
      //                   value={formik.password}
      //                   onChange={formik.handleChange}
      //                   placeholder="Password"
      //                   // clearOnEscape
      //                   size="large"
      //                   type="password" autoComplete="on"/>
      //           </label>
      //         </div>
      //         <span className="text-center text-red-600">{error}</span>
      //         <br/>
      //         <div className='w-1/2 mx-auto justify-center items-center'>
                // <button  disabled={isLoading}
                // type='submit' className='w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md inline-flex items-center justify-center hover:shadow-md active:bg-slate-500'>
                //   {/* <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg> */}
                //   {/* <span className="text-center">login</span> */}
                //  {isLoading ?  (<svg className="animate-spin h-5 w-5 mr-3 " viewBox="0 0 24 24">
                //   <circle
                //       className="opacity-25"
                //       cx="12"
                //       cy="12"
                //       r="10"
                //       stroke="currentColor"
                //       strokeWidth="4"
                //     ></circle>
                //     <path
                //       className="opacity-75"
                //       fill="currentColor"
                //       d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                //     ></path>
                //   </svg>): <div></div>
                //   }
                //   {isLoading ? 'Processing...' : 'Sign In'}
                // </button>
      //           <br/>
      //           <br/>
      //           <div className=' active:scale-105'>
      //           <span className='text-white hover:text-gray-400 cursor-pointer' onClick={()=>navigate('/register')}>Sign Up</span>
      //           </div>
      //         </div>
      //         <div>
      //         </div>
      //   </form>
      //    {/* {!isSigningIn && <SignupForm/>} */}
        
      //  </div>
    // </div>
  // );
}
