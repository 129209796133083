import React, { useState, useRef, useEffect } from 'react';
import uploadGif from '../assets/icons8-upload.gif';
import { useKiivuUpload } from '../context/KiivuUploadContext';
import FileUploader from './FileUploader';
import { useAuthHeader } from 'react-auth-kit';


const FileUploadDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const token = useAuthHeader();
  const {fileUploadProcesses, setFileUploadProcess, data, loading, error, progresses, uploadFile} = useKiivuUpload();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const btnRef = useRef()
  useEffect(() => {
        const closeDropdown = e => {
            if (!btnRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    },);
  

    // const handleFileUpload = async () => {
        
    //   try {
    //     const response = await axios.post(props.url, props.formData, {
    //       headers: {
    //         'Authorization': props.token,
    //         'Content-Type': 'multipart/form-data',
    //       },
    //       onUploadProgress: (progressEvent) => {
    //         const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //         setUploadProgress(progress);
    //       },
    //     });
    
    //     console.log('File uploaded successfully:', response.data);
    //     // Handle success (e.g., show success message)
    //   } catch (error) {
    //     console.error('Error uploading file:', error);
    //     // Handle error (e.g., show error message)
    //   }
    // };
    // handleFileUpload();



  return (
    <div ref={btnRef} className="relative inline-block text-left bg-transparent w-auto">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center items-center outline-none ml-2 px-1 py-2 text-sm font-semibold text-gray-900 ring-gray-300"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
         <div className='size-fit'>
           {progresses}
           <img src={uploadGif} alt=''/>
         </div>
          {/* Why  */}
          {/* <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
          </svg> */}
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2  sm:origin-top-right w-96 origin-center
                     rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1 px-1 rounded-sm" role="none">
            {fileUploadProcesses.map((item, index) => (
              // <div className='text-black' key={index}>{item.name}</div>
              <FileUploader key={index} token={token()} name={item.name} url={item.url} formData={item.formData}/>
            ))}
            {/* <form method="POST" action="#" role="none"> */}
            
            {/* </form> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadDropdown;
