import React, { useEffect } from 'react'
import './login/login.css'
import {useSignIn} from 'react-auth-kit';
import { useFormik,Formik, Form, Field, ErrorMessage } from "formik";
import axios, { AxiosError } from "axios";
import { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import {useIsAuthenticated} from 'react-auth-kit';
import AuthRequestBody from '../config/authcredential';


const Register = () => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(true);
  const navigate = useNavigate();
  const signIn = useSignIn();
  
 
  document.title = 'KIIVU';

  const onSubmit = async(values) => {
    setLoading(true);
    setError("");
   
    if(values.name === ""){
        setError('Name is empty');
        setLoading(false);
        return;
      }
    if(values.email === ""){
      setError('Email is empty');
      setLoading(false);
      return;
    }
    if(values.password === ""){
      setError('password is empty');
      setLoading(false);
      return;
    }
    if(values.confirmPassword !== values.password){
        setError('Confirm password!');
        setLoading(false);
        return;
    }
    const signUpUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/users';
    const url = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/token';
    try {
      const response = await axios.post(
        url,
        AuthRequestBody(values)
      );
      console.log(response);
      if(response.status !== 200){
        setLoading(false);
        return;
      }
      // console.log(response.data);
      // return;
      const signInResult = signIn({
        token: response.data.access_token,
        refreshToken:response.data.refresh_token,
        refreshTokenExpireIn:response.data.expires_in,
        expiresIn: response.data.expires_in,
        tokenType: "Bearer",
        authState: { email: values.email },
      });
      
      if(signInResult){
        navigate("/");
      }else{
        console.log('not signed in..');
      }
     
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError('Email or Password is incorrect!');
      // throw(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      name:"",
      email: "",
      password: "",
      confirmPassword:""
    },
    onSubmit,
  });


  return (
    <div className='logincontainer w-screen h-screen flex items-center justify-center'>
       <div className='w-full h-4/5 sm:h-3/5 xl:w-1/3 sm:w-1/2 bg-gray-800 rounded-3xl shadow-md'>
        {/* <div className='absolute inset-0 flex justify-center items-center -z-0 backdrop-filter backdrop-blur-sm bg-opacity-10'/> */}
         <form onSubmit={formik.handleSubmit} className='w-full h-full flex flex-col justify-around'>
              <span className=' text-3xl text-white'>Register a new account</span>
              <div>
                <label>
                  {/* {'Email: '} */}
                <input  
                className='w-1/2 rounded-md px-7 py-2'
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Name"
                        // clearOnEscape
                        size="large"
                        type="text"/>
                </label>
                <br/>
                <br/>
                <label>
                  {/* {'Email: '} */}
                <input  
                className='w-1/2 rounded-md px-7 py-2'
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email"
                        // clearOnEscape
                        size="large"
                        type="email" />
                </label>
                <br/>
                <br/>
                <label>
                  {/* {'Email: '} */}
                <input  
                className='w-1/2 rounded-md px-7 py-2'
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder="Password"
                        // clearOnEscape
                        size="large"
                        type="password" />
                </label>
                <br/>
                <br/>
                <label>
                  {/* {'Email: '} */}
                <input  
                className='w-1/2 rounded-md px-7 py-2'
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        placeholder="Confirm Password"
                        // clearOnEscape
                        size="large"
                        type="password" />
                </label>
                <br/>
                <br/>
              </div>
              <span className="text-center text-red-600">{error}</span>
              <br/>
              <div className='w-1/2 mx-auto justify-center items-center'>
                <button  disabled={isLoading}
                type='submit' className='w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md inline-flex items-center justify-center hover:shadow-md active:bg-slate-500'>
                  {/* <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24"></svg> */}
                  {/* <span className="text-center">login</span> */}
                 {isLoading ?  (<svg className="animate-spin h-5 w-5 mr-3 " viewBox="0 0 24 24">
                  <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 4.411 2.686 8.166 6.429 9.579l1.571-2.288z"
                    ></path>
                  </svg>): <div></div>
                  }
                  {isLoading ? 'Processing...' : 'Register'}
                </button>
                <br/>
                <br/>
                <div className=' active:scale-105'>
                <span className='text-white hover:text-gray-400 cursor-pointer' onClick={()=>navigate('/login')}>Log In</span>
                </div>
              </div>
              <div>
              </div>
        </form>
         {/* {!isSigningIn && <SignupForm/>} */}
        
       </div>
    </div>
  )
}

export default Register