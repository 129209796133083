import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import KiivuAuthProvider from './context/KiivuAuthProvider';
// import {createStore} from 'react-auth-kit';
import axios from 'axios';
import { AuthProvider, createRefresh } from "react-auth-kit";
import { client_id, client_secret, loginUrl } from './config/authcredential';
import { KiivuAuthProvider } from './context/KiivuAuthProvider';
import { DashboardProvider } from './context/DashboardContext';
import { LibraryProvider } from './context/LibraryContext';
import { KiivuUploadProvider } from './context/KiivuUploadContext';


// const store = createStore({
//   authName:'_auth',
//   authType:'cookie',
//   cookieDomain: window.location.hostname,
//   cookieSecure: window.location.protocol === 'https:'
//  })
const refresh = createRefresh({
  interval: 3600, // The time in sec to refresh the Access token,
  refreshApiCallback: async (param) => {
    try {
      const requesBody = {
        // param,
        'client_id' : client_id,
        'client_secret':client_secret,
        'grant_type':'refresh_token',
        'refresh_token': param.refreshToken
      }
      const response = await axios.post(loginUrl, requesBody, {
        // headers: {'Authorization': `Bearer ${param.authToken}`},
      })
      console.log('refreshing token');
      return {
        isSuccess: true,
        newAuthToken: response.data.token,
        newAuthTokenExpireIn: 3600,
        newRefreshTokenExpiresIn: 7200
      }
    }
    catch(error){
      console.error(error)
      return {
        isSuccess: false
      } 
    }
  }
})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <React.StrictMode>
     <KiivuAuthProvider>
        <AuthProvider
              authType={"cookie"}
              authName={"_auth"}
              cookieDomain={window.location.hostname}
              cookieSecure={false}
              refresh={refresh}
            >
             <KiivuUploadProvider>
             <DashboardProvider>
                 <LibraryProvider>
                 <BrowserRouter>
                    <App />
                  </BrowserRouter>
                 </LibraryProvider>
              </DashboardProvider>
             </KiivuUploadProvider>
            </AuthProvider>
     </KiivuAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
