import React, { useState, useRef } from 'react';
import VideoThumbnail from 'react-video-thumbnail';


const KiivuVideoThumbnail = () => {
  const [thumbnail, setThumbnail] = useState(null);
  const [url, setUrl] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setUrl(url);
    //   if (videoRef.current) {
    //     console.log('loading video');
    //     videoRef.current.src = url;
    //     console.log(videoRef.current.src);
    //     videoRef.current.onload = () => generateThumbnail();
    //   }
    }
  };

  // Function to generate thumbnail from video
  const generateThumbnail = () => {
    console.log('generating ..')
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Set canvas dimensions to match video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the video frame to the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert canvas to data URL and set as thumbnail
    const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
   // console.log(thumbnailDataUrl);
    setThumbnail(thumbnailDataUrl);
  };

  return (
    <div>
      <input type="file" accept="video/mp4" onChange={handleFileChange} />
      <video ref={videoRef} style={{ display: 'none', width:'1920', height:'1080'}} />
      <canvas ref={canvasRef} style={{ display: 'none',width:'1920', height:'1080' }} />
      {thumbnail && <img src={thumbnail} alt="Video Thumbnail" style={{width:'1920', height:'1080' }}/>}
      <VideoThumbnail
          videoUrl={url}
        //   videoUrl="https://dl.dropboxusercontent.com/s/7b21gtvsvicavoh/statue-of-admiral-yi-no-audio.mp4?dl=1"
         // thumbnailHandler={(thumbnail) => setThumbnail(thumbnail)}
        //   width={1920}
        //   height={1080}
      />
      {/* <image src={thumbnail}/> */}

    </div>
  );
};

export default KiivuVideoThumbnail;
