import React, { createContext, useContext, useState } from 'react';



export const LocationContext = createContext();

// Create a context provider component
const InitialPageProvider = ({ children }) => {
  const [initialPage, setInitialPage] = useState(true);

  return (
    <LocationContext.Provider value={{ initialPage, setInitialPage }}>
      {children}
    </LocationContext.Provider>
  );
};

export default InitialPageProvider;