import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import Dashboard from './layouts/dashboard';
import Login from './layouts/login/login';
// import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import { RequireAuth } from "react-auth-kit";
import Library from './layouts/library';
import Devices from './layouts/devices';
import TopNavBar from './components/TopNavBar';
import { useRef, useState, useEffect } from 'react';
import {useIsAuthenticated} from 'react-auth-kit';
import KiivuSideBarMenu from './components/kiivu_sidebar';
import Register from './layouts/register';
import { useLocation } from 'react-router-dom';
import { useKiivuAuth } from './context/KiivuAuthProvider';
import { useKiivuUpload } from './context/KiivuUploadContext';
import { useDashboard } from './context/DashboardContext';



function App() {
  const menuRef = useRef();
  // const isAuthenticated = useIsAuthenticated();
  // const { initialPage } = useContext(LocationContext);
  const {signedIn, klogin, klogout} = useKiivuAuth();
  const isAuthenticated = useIsAuthenticated()
   
  const {fileUploadProcesses, setFileUploadProcess} = useKiivuUpload();

  const {fileProgresses} = useDashboard();
  


  const [showModal, setShowModal] = useState(false);

  // const [initialPage, setInitialPage] = useState(true);
  const toggleSidebar = ()=>{
    menuRef.current.classList.toggle('hidden');
 }
 let location = useLocation();

 const isProgressEmpty = (obj) => Object.keys(obj).length === 0;

  const sidebarPaths = ['/dashboard', '/library', '/devices'];

 
  useEffect(() => {
    const alertUser = (e) => {
      if(!isProgressEmpty(fileProgresses)) return;
      e.preventDefault();
     // const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
      e.returnValue = 'confirmationMessage';
    // window.confirm(confirmationMessage);

    };
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(()=>{
   if(isAuthenticated())
      klogin();
   else
      klogout();
   
   if(location.pathname === '/login' || location.pathname === '/register')
      klogout();
   
    return ()=>{

    }
  },[]);

  return (
    <div className="App flex flex-col h-screen bg-white">
      {/* <BrowserRouter> */}
      {/* {!initialPage && <TopNavBar toggleSidebar={toggleSidebar}/>}
      {!initialPage && <div className='md:block w-60 fixed left-0 mt-14'>
       <KiivuSideBarMenu ref={menuRef}/>
      </div>} */}
      {signedIn && (
          <>
            <TopNavBar toggleSidebar={toggleSidebar} />
            <div className='md:block lg:w-60 md:w-40 fixed left-0 mt-14 z-50'>
              <KiivuSideBarMenu ref={menuRef} />
            </div>
          </>
        )}
      <div className={`flex-1 w-auto ${signedIn && 'p-4 lg:ml-60 md:ml-40 pt-14'}`}>
        <Routes className='w-auto'>
            <Route
              path="/"
              element={
                <RequireAuth loginPath='/login'>
                  <Dashboard/>
                </RequireAuth>
              }
            ></Route>
             <Route
              path="/library"
              element={
                <RequireAuth loginPath='/login'>
                  <Library/>
                </RequireAuth>
              }
            ></Route>
             <Route
              path="/devices"
              element={
                <RequireAuth loginPath='/login'>
                  <Devices/>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/"
              element={
                <RequireAuth loginPath='/login'>
                  <Dashboard/>
                </RequireAuth>
              }
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register/>}></Route>
            <Route path='*' element={<Navigate replace to="/" />}/>
          </Routes>
        </div>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
