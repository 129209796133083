import React,{useRef, useEffect} from 'react'
import DropdownMenu from './dropdown_menu'
import { useNavigate } from 'react-router-dom';
import {useAuthHeader, useAuthUser, useSignOut,} from 'react-auth-kit';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { client_id, client_secret, logoutUrl } from '../config/authcredential';
import uploadGif from '../assets/icons8-upload.gif';
import { useKiivuAuth } from '../context/KiivuAuthProvider';
import FileUploadDropdown from './FileUploadDropdown';
import { useKiivuUpload } from '../context/KiivuUploadContext';

const TopNavBar = (props) => {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const authHeader = useAuthHeader();
    const token = authHeader().replace('Bearer ', '');
    const {signedIn, klogin, klogout} = useKiivuAuth();
    const {fileUploadProcesses, setFileUploadProcess} = useKiivuUpload();

    const authUser = useAuthUser();

    useEffect(()=>{
      document.title = 'KIIVU';
      if(authUser().userInfo ===null){
        logout();
      }
      if(authUser().userInfo.email===null){
        logout();
      }

   },[]);
   

    const logout = () => {
        // revokeToken().then(
        //     signOut()
        // );
        signOut();
        klogout();
        navigate("/login", { replace: true });
        window.location.reload();
      };
  //  const revokeToken = async()=>{
  //   const response = axios.post(logoutUrl, {
  //       'token': token,
  //       'client_id': client_id,
  //       'client_secret':client_secret
  //   });
  //   console.log(response);
  //  }
  // console.log(authUser());
  
  return (
    <div>
        <div className="backdrop-blur-md bg-white text-white w-full pr-2 flex items-center justify-between h-14 fixed top-0 z-50">
        <div className="w-full flex justify-between items-center">
          
          <div className="lg:w-60 md:w-40 h-14 bg-slate-950 hidden md:block">
            <Link to={'/'}>
             <h1 className="flex justify-start font-bold text-2xl h-full w-full p-4 text-white bg-slate-950">KIIVU</h1>
             </Link>
          </div>
          
          <div className="md:hidden flex items-center justify-center">
            <button id="menuBtn" onClick={props.toggleSidebar} className='active:scale-105'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-black">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
          </div>
          <div className="flex-auto flex flex-row max-w-56 sm:max-w-80 justify-center items-center ml-5">
            {/* <div className="absolute top-1 inline-flex items-center p-2">
            </div> */}
           <input className="w-full h-10 pl-10 sm:pr-4 py-1 text-base placeholder-gray-500 border outline-black rounded-full focus:shadow-outline text-black mr-2" type="search" placeholder="Search..." />
           <button className='active:scale-105'>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-black">
                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
           </button>
          </div>
          
          <div className="flex space-x-5 sm:space-x-1">
          {/* <button>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" size-6 text-black">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
          </svg> 
          </button> */}
          {/* <div className='h-2'> */}
          {/* <button className='aspect-square flex justify-center items-center'>
          <img src={uploadGif} alt=''></img>
          </button> */}
          {/* {fileUploadProcesses.length > 0 && <FileUploadDropdown/>}  */}
          {fileUploadProcesses.length > 0 && <FileUploadDropdown/>}
          <DropdownMenu callback={logout} name={authUser().userInfo.email}/>
          {/* </div> */}
        </div>

        </div>

        {/* Notification and Profile Icons */}
       
      </div>
     <hr/>
    </div>
  )
}

export default TopNavBar