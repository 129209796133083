import { createContext, useContext, useState,useEffect } from "react";
import axios from 'axios';
import { Endpoints } from "../config/endpoints";
import { create_mediaUrl } from "../config/authcredential";

export const DashboardContext = createContext();

export const DashboardProvider = ({children})=>{

  const [metaData, setMetaData] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);
  const [dashboardVideos, setDashboardVideos] = useState([]);

  const [fileProgresses, setFileProgresses] = useState([]);
  const [fileProcesses, setFileProcesses] = useState([]);

  const[requestQueue, setRequestQueue] = useState([]);
  const MAX_CONCURRENT_REQUESTS = 3;
  let activeRequests = 0; 

//   const [videoProgress, setProgress] = useState(0);
   const [videoProcesses, setVideoProcesses] = useState([{status: 'initiated', uploading: false, progress:0},{status: 'initiated', uploading: false, progress:0}]);  
 
    
    // const setFileProgress = (p)=>{
    //     setFileProgresses(pre=> [...pre,p]);
    // }

    // const setFileProcess = (p)=>{
    //     setFileProcesses(pre=> [...pre,p]);
    // }


    // console.log(dashboardVideos[0]);
    const uploadVideoFile = async (request) => {
        // setLoading(true);
        // setError(null);
        // setProgress(0);
        // sendRequests();
        // return;
        console.log(request);
        const {url, formData, headers} = request;
        try {
        const response = await axios.post(url, formData, {
            headers,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                setVideoProcesses(prevProcesses => 
                    prevProcesses.map((process, index) => 
                      index === 0 ? {
                        ...process, 
                        status: 'uploading', 
                        uploading: true, 
                        progress: percentCompleted
                      } : process
                    )
                  );
                  console.log(percentCompleted);
               // setProgress(percentCompleted);
            },
            });
            console.log(response.status);
            if(response.status === 201)
            {
                let temp = dashboardVideos;
                temp[0].uploading = false;
                setDashboardVideos(temp);
                setVideoProcesses(prevProcesses => 
                    prevProcesses.map((process, index) => 
                      index === 0 ? {
                        ...process, 
                        status: 'complete', 
                        uploading: false, 
                        progress: 100
                      } : process
                    )
                  );
            }
          // setData(response.data);
          return {
            status: 1,
            response: response.data
          };
        } catch (err) {
          // setError(err);
          throw err;
        } finally {
          // setLoading(false);
        }
      };

    const processQueue = async () => {
        while (requestQueue.length > 0 && activeRequests < MAX_CONCURRENT_REQUESTS) {
          const { id,url,formData, headers, thumbnail } = requestQueue.shift();
          activeRequests++;
          try {
            const response = await axios.post(url, formData, {
                headers,
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    //  console.log( formData.file.name + ' : ',percentCompleted);
                   // setP1({status: 'uploading',uploading:true, progress: percentCompleted });
                    // const pro = {
                    //     [id]:{status: 'uploading',uploading:true, progress: percentCompleted }
                    // }
                    setFileProgresses(prevProgress => ({
                        ...prevProgress,
                        [id] : {status: 'uploading',uploading:true, progress: percentCompleted }
                      }));
                    // console.log('process ', JSON.stringify(fileProgresses));
                    
                    // setVideoProcesses(prevProcesses => 
                    //     prevProcesses.map((process, index) => 
                    //       index === 0 ? {
                    //         ...process, 
                    //         status: 'uploading', 
                    //         uploading: true, 
                    //         progress: percentCompleted
                    //       } : process
                    //     )
                    //   );
                      
                   // setProgress(percentCompleted);
                },
                });
                if(response.status === 201){          
                    setFileProgresses(prevProgress => ({
                        ...prevProgress,
                        [id]: {status: 'completed',uploading:false, progress: 100 }
                      }));
                      const formData = new FormData()
                      formData.append('medium[artwork]', thumbnail)
                      // console.log(create_mediaUrl + '/' + id + '/upload_artwork');
                      // console.log(thumbnail.type)
                      // console.log(thumbnail)
                      // console.log('....')
                      const upworkResponse = await axios.patch(create_mediaUrl + '/' + id + '/upload_artwork', formData, {headers});
                      console.log(upworkResponse.status);
                     if(upworkResponse.status === 200){
                      removeProgressData(id);
                     }
                      // setTimeout(() => {
                      //  removeProgressData(id);
                      // }, 2000);
                }
          } catch (error) {
            if(axios.isCancel(error)){
              await axios.patch(`${Endpoints.baseUrl}/${id}/update_status?status=canceled`, headers);
           }else{
             console.log('Upload failed',error)
           }
           // reject(error);
          } finally {
            activeRequests--;
            console.log(JSON.stringify(fileProgresses));
            //processQueue(); // Process next in the queue
          }
        }
      };

      const removeProgressData = (key) => {
        const { [key]: _, ...rest } = fileProcesses; // Exclude the key to remove
        setFileProgresses(rest);
      };


    // const addToProgressQueue = (progress)=>{
        
    // }

    const addToProgressQueue = (progress)=>{
        if(Array.isArray(fileProgresses))
          setFileProgresses(prevFileProgresses => [...prevFileProgresses, progress]);
    }

    const addToQueue = (request) => {
        return new Promise((resolve, reject) => {
        //   requestQueue.push({ url, options, resolve, reject });
          requestQueue.push(request);
          processQueue(); // Start processing if not already started
        });
      };


    // useEffect(() => {
    //     const sendRequests = async () => {
    //         return;
    //         try {

    //         //   await Promise.all(fileProcesses.map(process =>
    //         //      {
    //         //       const {url, formData, headers} = process;
    //         //       try {
    //         //        axios.post(url, formData, {
    //         //           headers,
    //         //           onUploadProgress: (progressEvent) => {
    //         //               const { loaded, total } = progressEvent;
    //         //               const percentCompleted = Math.round((loaded * 100) / total);
    //         //               setFileProgresses(prevProgress => ({
    //         //                   ...prevProgress,
    //         //                   [process.id]: {status: 'uploading',uploading:true, progress: percentCompleted }
    //         //                 }));
    //         //                // console.log(process.id + ':' + percentCompleted);
    //         //                 // console.log(JSON.stringify(fileProgresses));
    //         //                 // console.log(fileProgresses[process.formData.id]);
    //         //             //   setVideoProcesses(prevProcesses => 
    //         //             //       prevProcesses.map((process, index) => 
    //         //             //         index === 0 ? {
    //         //             //           ...process, 
    //         //             //           status: 'uploading', 
    //         //             //           uploading: true, 
    //         //             //           progress: percentCompleted
    //         //             //         } : process
    //         //             //       )
    //         //             //     );
    //         //             // setProgress(percentCompleted);
    //         //           },
    //         //           }).then(res => {
    //         //             // console.log(typeof fileProcesses);
    //         //               if(res.status === 201)
    //         //                   {
    //         //                       let temp = dashboardVideos;
    //         //                       temp[0].uploading = false;
    //         //                       setDashboardVideos(temp);
    //         //                       setVideoProcesses(prevProcesses => 
    //         //                           prevProcesses.map((process, index) => 
    //         //                             index === 0 ? {
    //         //                               ...process, 
    //         //                               status: 'complete', 
    //         //                               uploading: false, 
    //         //                               progress: 100
    //         //                             } : process
    //         //                           )
    //         //                         );
    //         //                 //    setFileProcesses([]);
    //         //                 //console.log('process', JSON.stringify(fileProcesses));
    //         //                   }
    //         //                   return {
    //         //                       status: 1,
    //         //                       response: res.data
    //         //                     };
                          
    //         //           });
    //         //       } catch (err) {
    //         //         // setError(err);
    //         //         throw err;
    //         //       } finally {
    //         //         // setLoading(false);
    //         //       }
    
    //         //      }
    //         //   ));
          
    //     } catch (error) {
    //             console.log(error);
    //          // console.error('Error processing:', error);
    //         }
    //       };
    //     //   if (Array.isArray(fileProgresses) &&  fileProgresses.length > 0) {
    //     //     sendRequests();
    //     //   }
        
    //   }, [dashboardVideos, fileProcesses, fileProgresses]);  


    return(
        <DashboardContext.Provider value={{fileProgresses,dashboardVideos,setDashboardVideos,addToProgressQueue,setFileProgresses, uploadVideoFile, videoProcesses,fileProgresses, fileProcesses,setFileProcesses,addToQueue,metaData,setMetaData}}>
           {children}
        </DashboardContext.Provider>
    );
}

export const useDashboard = ()=>{
    return useContext(DashboardContext);
}













// const BATCH_SIZE = 5;

// const processQueue = async () => {
//   while (requestQueue.length > 0) {
//     const batch = requestQueue.splice(0, BATCH_SIZE); // Take a batch of requests
//     activeRequests += batch.length;

//     try {
//       const batchPromises = batch.map(({ url, options, resolve, reject }) =>
//         axios(url, options).then(resolve).catch(reject)
//       );
//       await Promise.all(batchPromises);
//     } catch (error) {
//       console.error('Error processing batch:', error);
//     } finally {
//       activeRequests -= batch.length;
//       processQueue(); // Process next batch in the queue
//     }
//   }
// };
