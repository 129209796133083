import React,{forwardRef, useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useDashboard } from '../context/DashboardContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'
const KiivuSideBarMenu = forwardRef((props, ref) => {


  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const {metaData} = useDashboard();
  
  const handleClick = ()=>{
    ref.current.classList.toggle('hidden');
    if (ref.current) {
      if (ref.current.classList.contains('hidden')) {
        setSidebarVisible(prevState => false);
      } else {
        setSidebarVisible(prevState => true);
      }
    }
  }
  
  // const [widthPercentage, setWidthPercentage] = useState(0);
  // // useEffect(() => {
  // //   setWidthPercentage(calculateWidth());
  // //   console.log(widthPercentage)
  // // },[]);
  
  // const calculateWidth = () => {
  //   if(metaData === null) return;
  //   const newWidth = (metaData.used_storage / 20).toFixed(2); // Random percentage between 0 and 100
  //   console.log(newWidth)
  //   return `${newWidth}%`;
  // };
  const fileSizeFromByte = (bytes)=>{
    // const bytesToMB = () => (bytes / (1024 * 1024)).toFixed(2);
    const bytesToGB = () => (bytes / (1024 * 1024 * 1024)).toFixed(2);
    const sizeInGB = bytesToGB();
    return sizeInGB;
    // const sizeInMB = bytesToMB();
    // if(sizeInGB >= 1)
    //   return sizeInGB + ' GB'
    // else{
    //   return sizeInMB + ' MB'
    // }
   }

  return (
    <div ref={ref} className={`pb-4 w-full bg-gray-100 sm:bg-white lg:w-60 md:w-40  sm:h-full h-auto  
    // transition-transform duration-300 ease-in-out transform  
    // ${ isSidebarVisible ? '' : 'translate-y-0 opacity-100'}
    flex-col md:flex hidden fixed left-0 z-20 rounded-bl-lg rounded-br-lg sm:rounded-none  border-r border-gray-200`} id="sideNav">
    <nav className='mt-10'>
     
    <Link to={'/'} onClick={handleClick}>
      <div className="flex flex-row py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-gradient-to-r hover:bg-gray-100 cursor-pointer" href="#">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
      </svg>
      <span className='ml-3 text-gray-700'>Dashboard</span>
      </div>
      </Link>
      <Link to={'/library'} onClick={handleClick}>
      <div className="flex flex-row py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-gradient-to-r  hover:bg-gray-100" href="#">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
      </svg>
      <span className='ml-3 text-gray-700'>Library</span>
      </div>
      </Link>
      <Link to={'/devices'} onClick={handleClick}>
      <div className="flex flex-row py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-gray-100" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
          </svg>
      <span className='ml-3 text-gray-700'>Devices</span>
      </div>
      </Link>
    </nav>
    
    

    {/* <div className="bg-gradient-to-r from-cyan-300 to-cyan-500 h-px mt-auto"></div> */}
    <Link to={'/dashboard'} className='mt-auto mb-3' onClick={handleClick}>
      {/* <div className="flex flex-row text-gray-500 py-2.5 px-4 mt-4 rounded transition duration-200 hover:bg-gradient-to-r hover:bg-gray-100 hover:text-white" href="#">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-gray-700">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
        <span className='ml-3 text-gray-700'>Settings</span>
      </div> */}
     <span className='text-sm'>Storage ({metaData && fileSizeFromByte(metaData.used_storage)} / 20 GB)</span>
     {metaData && (
      //  <div className='flex flex-col '>
      //   <div className="flex w-auto h-3 mx-10 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
      //     <div style={{widthPercentage}} className=" flex flex-col justify-center rounded-full overflow-hidden bg-black text-xs text-white text-center whitespace-nowrap dark:bg-blue-500 transition duration-500">{fileSizeFromByte(metaData.used_storage)/0.2}%</div>
      //   </div>
      //   <p className='text-black text-s'>{fileSizeFromByte(metaData.used_storage)/0.2}%</p>
      //   </div>
      <div className="bg-gray-200 mx-5 rounded-xl shadow-sm overflow-hidden p-1">
        <div className="relative h-3 flex items-center justify-center">
          <div style={{width:`${fileSizeFromByte(metaData.used_storage)/0.2}%`}} className="absolute top-0 bottom-0 left-0 rounded-lg bg-slate-500"></div>
          <div className="relative text-black font-medium text-sm">{(fileSizeFromByte(metaData.used_storage)/0.2).toFixed(2)}%</div>
        </div>
      </div>
     )}
      
      </Link>
      <div className="bg-gradient-to-r bg-gray-100 h-px"></div>
    {/* Copyright at the bottom of the sidebar */}
    <p className=" mb-16 px-5 py-3 text-left text-xs font-bold text-gray-300">Copyright KIIVU@2024</p>
  </div>
  )
});

export default KiivuSideBarMenu