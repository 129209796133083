import React, {useEffect, useState, useRef, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';
import {useIsAuthenticated, useSignOut,} from 'react-auth-kit';
import { useAuthHeader } from 'react-auth-kit';
import {useDropzone} from 'react-dropzone'
import Dropzone from 'dropzone';
import axios from 'axios';
import 'dropzone/dist/dropzone.css';
import DropdownMenu from '../components/dropdown_menu';
import SidebarMenu from '../components/sidebar_menu';
import AssetCard from '../components/asset_card';
import VideoItem from '../components/video_item';
import { create_mediaUrl, s3_endpointUrl } from '../config/authcredential';
import { useKiivuAuth } from '../context/KiivuAuthProvider';
import { useDashboard } from '../context/DashboardContext';
import { useKiivuUpload } from '../context/KiivuUploadContext';
import VideoThumbnail from 'react-video-thumbnail';
import uploadButtonImg from '../assets/images/uploadButton.jpg'
import defaultImage from '../assets/images/kiivu-default-thumbnail.jpg';
import { Endpoints } from '../config/endpoints';
import FileDropZone from './KiivuDropzone';
import KiivuDropzone from './KiivuDropzone';


const Dashboard = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const btnRef = useRef();
  const fileInputRef = useRef(null);
  const childRef = useRef();
  const authHeader = useAuthHeader();
  const token = authHeader();
  const dropzoneRef = useRef(null);
  const dropzoneInstanceRef = useRef(null);
  const uploadAllButtonRef = useRef(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  const [fileData, setFileData] = useState({})

  // const {fileUploadProcesses, setFileUploadProcess, data, loading, error, progresses, uploadFile} = useKiivuUpload();

  
  const {signedIn, klogin, klogout} = useKiivuAuth();


  const [nameValue, setNameValue] = useState('');
  const [descriptionValue, setdescriptionValue] = useState('');
 
  const isAuthenticated = useIsAuthenticated();

  const {dashboardVideos, setDashboardVideos, addToProgressQueue,addToQueue,setMetaData} = useDashboard([]);
  const [progress, setProgress] = useState(0);
  
  // const [isReadyToUpload, setisReadyToUpload] = useState(false);

  const url = "https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/creator/nftassets";

  var presigned_url = 'https://s3.us-east-1.wasabisys.com/us-west-1-aws-cdn.com';
  //var presigned_url = 'https://s3.us-east-1.wasabisys.com';
  
  const [isSortOpen, setIsSortOpen] = useState(false);
  const toggleDropdown = () => {
    setIsSortOpen(!isSortOpen);
  };

  const closeDropdown = (event) => {
    event.preventDefault();
    setIsSortOpen(false);
  };


  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    handleReset();
  };

  const [currentFile, setCurrentFile] = useState(null);

  const cancelFile = ()=>{
   // dropzoneInstanceRef.current.removeFile(currentFile);
   
   dropzoneInstanceRef.current.removeFile(currentFile);
   setNameValue('');
   setdescriptionValue('');
   setCurrentFile(null);
   closeModal();
  }


  // const [reqBody, setReqbody] = useState({"Content-Type": "video/mp4"});

  const headers = {
   // 'Content-Type': 'application/json', // Specify content type JSON
    'Authorization': token, // Example Authorization header
  };
 const reqBody = {"Content-Type": "video/mp4"};

  const getPresignedUrlandCredential = async(record)=>{
    console.log(fileData)
    console.log('//////')
    const body = {
      name: record.name,
      description: record.description,
      original_filename: record.fileName,
      content_type: currentFile.type,
      media_file:'',
      art_work:'',
      media_type:'',
      file_size: currentFile.size,
      duration:fileData['duration'],
      resolution:fileData['resolution'],
      date_taken:currentFile.lastModified
    };



    
   // console.log('body value is ' + body);
      var res = await axios.post(Endpoints.create_mediaUrl, body ,{headers : headers}).then(response =>{
      // console.log(response.data);
      var data = response.data;
      
      // response.data.medium.uploading = true;
      // response.data.medium.progress = progresses;
      // const pro = {
      //   [response.data.medium.id]: {status: 'uploading',uploading:true, progress: 0 }
      //  }
      // setFileProgresses(p => [...p, pro]);
      // response.data.medium.artwork.url = '/kiivu-default-thumbnail.jpg';
      setDashboardVideos(videos=> [response.data.medium,...videos]);
      console.log('presigned url is ---> ',data.presigned_url.url);
      presigned_url = data.presigned_url.url;
      var rbd = {
        "Content-Type" :"video/mp4"
      };
      rbd.key = data.presigned_url.fields.key;
      rbd.success_action_status = data.presigned_url.fields.success_action_status;
      rbd.acl = data.presigned_url.fields.acl;
      rbd.policy = data.presigned_url.fields.policy;
      rbd['x-amz-credential'] = data.presigned_url.fields['x-amz-credential'];
      rbd['x-amz-algorithm'] = data.presigned_url.fields['x-amz-algorithm'];
      rbd['x-amz-date'] = data.presigned_url.fields['x-amz-date'];
      rbd['x-amz-signature'] = data.presigned_url.fields['x-amz-signature'];
      rbd.duration = reqBody.duration;
      rbd.resolution = reqBody.resolution;
      rbd['date_taken'] = currentFile.lastModified;
      rbd['content-type'] = currentFile.type;
      rbd['file_size'] = currentFile.size;
      console.log(rbd)
      dropzoneInstanceRef.current.on("sending", handleSending);
      return {
         id:response.data.medium.id,
         url: presigned_url,
         formData:rbd,
      };

      // return response.data;
     }).catch(error => {
      console.error('Error: ', error);
      return error;
     });
     return res;
     
  }
 
  const completeFile = ()=>{
     if(nameValue.trim() === ''){
         alert('Please fill in the blank');
         return;
     }else{
      var record = {
         file : currentFile,
         fileName: currentFile.name,
         name: nameValue,
         description: descriptionValue

      };
      getPresignedUrlandCredential(record).then(async(res)=>{
        console.log(res);
        // return;
       // setDashboardVideos(res['mediam']);
        // res['formData']['file'] = dropzoneInstanceRef.current.getFilesWithStatus(Dropzone.QUEUED);
        res['formData']['file'] = currentFile;
        // console.log(token);
        res['headers'] = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
          }
        res['name'] = currentFile.name;
        res['thumbnail'] = thumbnail;
        // console.log(res);
        // return;
        // setFileProcesses(p => {
        //   console.log(typeof p);
        //  return [...p, res];
        // });
        const pro = {
          [res.id]: {status: 'uploading',uploading:true, progress: 0 }
         }
        // setFileProgresses(p => [...p, pro]);
        addToProgressQueue(pro);
        addToQueue(res);
        // if (dropzoneInstanceRef.current) {
        //   const addedFiles = dropzoneInstanceRef.current.getFilesWithStatus(Dropzone.QUEUED);
        //   if(addedFiles.length === 0){
        //     alert('Please select video files');
        //     return;
        //   }
        //  dropzoneInstanceRef.current.processQueue();
        // }
      });
      setNameValue('');
      setdescriptionValue('');
     }
     closeModal();

  }

  const removeFileFromFileTracker = (file)=>{
   
    // for(var f in fileTrackers){
    //   console.log(f);
    // }
  }
   


 const tempUpload = async(url, formData)=>{

  const h = {
    'Content-Type': 'multipart/form-data',
    'Authorization': token, 
  }
  console.log(formData);
  // return;
  const response = await axios.post(url, formData, {
    h,
    onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        console.log(percentCompleted);
        setProgress(percentCompleted);
    },
    });
    console.log(response);

 }
 

  const header = {
    'Authorization': token,
    // 'Content-Type': 'multipart/form-data', // Required for file uploads
  };

  
  const handleNameInputChange = (event) => {
    setNameValue(event.target.value);
  };
  const handleDescriptionInputChange = (event) => {
    setdescriptionValue(event.target.value);
  };
  
  function handleSending(file, xhr, data){
    data.append("key", reqBody.key);
    data.append("success_action_status", reqBody.success_action_status);
    data.append("acl", reqBody.acl);
    data.append("Content-Type", reqBody['Content-Type']);
    data.append("policy", reqBody.policy);
    data.append("x-amz-signature", reqBody['x-amz-signature']);
    data.append("x-amz-credential", reqBody['x-amz-credential']);
    data.append("x-amz-algorithm", reqBody['x-amz-algorithm']);
    data.append("x-amz-date", reqBody['x-amz-date']);
    // data.forEach(function(value, key) {
    //   console.log(key, value);
    // });
  }

  const getFileNameWithoutExtension =(filename)=>{
    // Find the last index of the dot character (.)
    var lastIndex = filename.lastIndexOf('.');

    // If a dot (.) is found and it is not the first character, extract the substring before it
    if (lastIndex !== -1 && lastIndex !== 0) {
        return filename.substring(0, lastIndex);
    } else {
        // If there is no dot (.) or it's at the beginning, return the original filename
        return filename;
    }
}

  // const [medias, setMedias] = useState([]);

  const getMedia = async()=>{
    const res = await axios.get(Endpoints.dashboard_mediaUrl ,{headers : headers})
              .then(response =>{
                 const temp = response.data;
                 // console.log(temp);
                 setDashboardVideos(temp['media']);
                 setMetaData(temp['meta']);
                 return 'ok';
              })
              .catch(error=> {console.error(error); return 'not ok'});
   return res;
 }

  useEffect(()=>{
   
    getMedia();
    Dropzone.autoDiscover = false;
    dropzoneInstanceRef.current = new Dropzone(dropzoneRef.current, {
      url: presigned_url, 
      headers: header,
      method:'POST',
      parallelChunkUploads:true,
      paramName:'file',
      createImageThumbnails:true,
      acceptedFiles:'video/mp4',
      // params:reqBody,
      // chunking:true,
      // chunkSize: 50 * 1024 * 1024, // Replace with your file upload URL
      autoProcessQueue: false,
      maxFilesize: 100 * 1024 * 1024, // Max file size in MB
      maxFiles: 1, // Max number of files
      addRemoveLinks: true,
      error:function(file, message, xhr){
        return 'test error';
      },
      init: function() {
        this.on("addedfile", function(file) {
           setCurrentFile(file);
          // console.log(this.files);
          setNameValue(getFileNameWithoutExtension(file.name));
          openModal();
          // console.log(this.getFilesWithStatus(Dropzone.ADDED));
        });
        this.on("success", function(file, response) {
          console.log("File uploaded successfully:", file, response);
          this.off("sending", handleSending);
          this.removeFile(file);
          setTimeout(()=>{
            getMedia();
            console.log('refresh list');
          }, 1000);
        });
        this.on("canceled", function(file, response) {
          // console.log("File uploaded successfully:", file, response);
          this.off("sending", handleSending);
          this.removeFile(file);
        });
        this.on("error", function(file, errorMessage) {
          console.error("Error uploading file:", file, errorMessage);
        });

        this.on("removedfile", function(file){
          removeFileFromFileTracker(file);
        });
        }
    
      },
    );

    const handleUploadAll = () => {
      if (dropzoneInstanceRef.current) {
        const addedFiles = dropzoneInstanceRef.current.getFilesWithStatus(Dropzone.QUEUED);
        if(addedFiles.length === 0){
          alert('Please select video files');
          return;
        }
        dropzoneInstanceRef.current.processQueue();
      }
    };


    if (uploadAllButtonRef.current) {
      uploadAllButtonRef.current.addEventListener('click', handleUploadAll);
    }
   


    return () => {
      if (uploadAllButtonRef.current) {
        uploadAllButtonRef.current.removeEventListener('click', handleUploadAll);
      }
      if (dropzoneInstanceRef.current) {
        dropzoneInstanceRef.current.destroy();
      }
    };
  }, ); 
   
  useEffect(() => {
    if(isAuthenticated())
      klogin();
   else
      klogout();
    
  },);



  // react dropzone implementation here
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log(acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


  
  useEffect(() => {
    const closeDropdown = e => {
        if (!btnRef.current.contains(e.target)) {
          setIsSortOpen(false);
        }
    }
    document.body.addEventListener('click', closeDropdown)
    return () => document.body.removeEventListener('click', closeDropdown)
}, [])


  // const alertUser = (e) => {
  //  // e.preventDefault();
  //   // if(window.confirm("this process can stop the upload, do you really want to abort it?")){
  //   //   alert('ok...');
  //   // }
  // };
 

  const handleReset = () => {
    if (childRef.current) {
      childRef.current.triggerFunction();
    }
    // if (fileInputRef.current) {
    //   // fileInputRef.current.value = '';
    //     // Create a temporary form to reset the file input
    //     const form = document.createElement('form');
    //     fileInputRef.current.parentNode.insertBefore(form, fileInputRef.current);
    //     form.appendChild(fileInputRef.current);
    //     form.reset();
    //     form.parentNode.insertBefore(fileInputRef.current, form);
    //     form.remove(); // Clean up the temporary form
    // }
};

 

  const handleFileChange = (event) => {
    //disabled={( fileProcesses && fileProcesses.length !== 0) ? true : false}
    const file = event.target.files[0];
    // setCurrentFile(file);
    // setNameValue(file.name);
    // openModal();
    if(!file) return;
      const url = URL.createObjectURL(file);
      // console.log(file)
      // console.log(url);
      // setVideoUrl(url);
      handleFile(file)
      const videoElement = videoRef.current;
      videoElement.src = url;
      const req = {}
      videoElement.onloadedmetadata = () => {
         req.duration = videoElement.duration
         req.resolution = `${videoElement.videoWidth}x${videoElement.videoHeight}`
        URL.revokeObjectURL(url); // Clean up the object URL
      };
      setFileData(req);
      setCurrentFile(file);
      setNameValue(getFileNameWithoutExtension(file.name));
      openModal();
   


  };
  
  const handleFile=(file)=>{
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement("video");

      const handleLoadedData = () => {
        if (snapImage(video)) {
          video.removeEventListener("loadeddata", handleLoadedData);
        }
      };

      const handleTimeUpdate = () => {
        if (snapImage(video)) {
          video.removeEventListener("timeupdate", handleTimeUpdate);
          video.pause();
        }
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      // video.currentTime = 10;
      video.play();
    };

    fileReader.readAsArrayBuffer(file);
  }


  const snapImage = (video) => {
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL();
    const success = image.length > 100000;

    if (success) {
      setThumbnail(image); // Update state with new thumbnail
      // Clean up
      URL.revokeObjectURL(video.src);
    }

    return success;
  }; 


 
const handleDragFile = (e)=>{
  console.log(e);
}


 const  handleThumbnail = (thumbnail)=>{
  console.log('loading new thumbnail', thumbnail)
  setThumbnail(thumbnail)
 }

 const handleUploadImgError = ()=>{

 }

  return (
    <div className='w-auto h-full bg-white flex flex-col pt-10'>
      {/* Modal Trigger Button */}
      {/* <button
        type="button"
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        onClick={openModal}
      >
        Open modal
      </button> */}
       
      {/* this is only for the purpose of extracting attribute of the video */}
       <video
        ref={videoRef}
        style={{ display: 'none' }} // Hide the video element
      />


      {/* Background backdrop */}
      {isOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" style={{zIndex:'1001'}}></div>
      )}

      {/* Modal panel */}
      {isOpen && (
        <div className="fixed inset-0 w-screen overflow-y-auto " style={{zIndex:'1050'}}>
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
               
                  <div className="w-full mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Please fill in the information</h3>
                    <div className="flex flex-col w-full">
                       <div className="p-4 overflow-y-auto">
                       <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                          {currentFile.name}
                        </label>
                        <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                          Name
                        </label>
                        <input
                          type="text"
                          maxLength="50"
                          id="input-label"
                          value={nameValue}
                          onChange={handleNameInputChange}
                          className="py-3 px-4 block w-full border border-black ring-black rounded-lg text-sm focus:border-black focus:ring-black dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:text-neutral-400"
                          // placeholder={nameValue}

                        />
                    </div>
                    <div className="p-4 overflow-y-auto">
                        <label htmlFor="input-label" className="block text-sm font-medium mb-2 dark:text-white">
                          Description (optional)
                        </label>
                        <textarea
                          type="text"
                          maxLength="200"
                          id="input-label"
                          value={descriptionValue}
                          onChange={handleDescriptionInputChange}
                          className="py-3 px-4 block w-full border border-black rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:text-neutral-400"
                          placeholder="description"
                        />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row w-full justify-end sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-black
                              text-white border border-black hover:bg-black
                                hover:scale-105 transition-transform duration-200 ease-in-out
                                active:scale-95
                               hover:text-white px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mr-2 sm:w-auto"
                  onClick={completeFile}
                >
                  {/* <img src='https://s3.us-east-1.wasabisys.com/biiga.net/kiivu-defaults/upload-media.png' className='bg-transparent' alt=''/> */}
            
                  <div>
                   Upload
                  </div>
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold border-black hover:bg-black hover:text-white text-gray-900 shadow-sm ring-1 ring-inset ring-black sm:mt-0 sm:w-auto"
                  onClick={cancelFile}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}





      <div className='w-full'>  
        <KiivuDropzone ref={childRef} handleInputFile = {handleFileChange}/>
        <div className='w-auto mx-10 hidden rounded-md border-gray-200 border-2 border-dashed' onDragEnd={handleDragFile}>
        
       <form className="dropzone mx-5 z-1 hidden" style={{border: 'none',padding:'1px'}} ref={dropzoneRef}>
       </form>
          {/* <div className="dz-message">Drag and Drop your files here <br/>or<br/><span className='font-bold text-blue-800'>Browse</span></div> */}
          {/* <div className="dz-message">Drag and Drop your files here <br/>or<br/></div> */}
     
          {/* <label for='fileUpload' className='hidden border border-black py-1 px-3 mt-1'><img  src={uploadButtonImg} alt="" onError={handleUploadImgError} /></label>
          <input id='fileUpload' ref={fileInputRef} type='file' className=''  accept="video/*" onChange={handleFileChange}/>
          */}
          {/* <div className='w-28 h-28'>
          <VideoThumbnail
             videoUrl={videoUrl}
             thumbnailHandler={(thumbnail) => handleThumbnail(thumbnail)}
               width={1920}
               height={1080}
              // style={{ display: 'none' }} 
          />
          </div> */}
        

       </div>
      <br/>
        <div className='mx-auto w-full flex gap-2 justify-center'>
          {/* <button ref={clearAllButtonRef} className=' w-36 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md inline-flex items-center justify-center hover:shadow-md active:bg-slate-500' 
          >Clear All</button> */}
          {/* <br/> */}
         {/* <button ref={uploadAllButtonRef} className='w-36 bg-white border border-black hover:bg-black hover:text-white text-gray-800 font-bold py-2 px-4 rounded-md inline-flex items-center justify-center hover:shadow-md active:bg-slate-500' 
          >Upload</button> */}
        </div>  
        <br/>
        {/* {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>} */}
      </div> 
      <div className='flex justify-between items-center mb-4 mx-4'>
      <h3 className="text-xl font-bold">Uploaded Videos</h3>
       {/* <div className='relative flex flex-row'> */}
       <div className="relative z-30">
      <button
        ref={btnRef}
        className="px-4 py-2 border border-gray-500 text-gray-800 rounded-md focus:outline-none"
        // onClick={toggleDropdown}
         onClick={()=>navigate('/library')}
      >
        View All{' '}
        {/* <svg
          className={`w-4 h-4 inline-block ml-1 -mr-1 ${isSortOpen ? 'transform rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
        </svg> */}
      </button>
      {isSortOpen && (
        <div className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Alphabetical (A-Z)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Alphabetical (Z-A)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Date (Newest)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Date (Oldest)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>File Size (Low to High)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>File Size (High to Low)</a>
          </div>
        </div>
      )}
    </div>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-x-6 gap-y-7 px-4'>
      {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'> */}
        {dashboardVideos.length > 0 && (
          dashboardVideos.map((m, index)=> (
            <VideoItem refresh={getMedia} media ={m} key={index}/>
          ))
        )}
      </div>
    </div>
  )
}

export default Dashboard