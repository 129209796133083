import React,{useEffect, useState,useRef} from 'react'
import { useKiivuAuth } from '../context/KiivuAuthProvider';
import {useIsAuthenticated, useSignOut,} from 'react-auth-kit';
// import KiivuVrPlayer from '../components/kiivu_vr_player';
import defaultImage from '../assets/images/kiivu-default-thumbnail.jpg';
import VideoThumbnail from './video_thumbnail';
// import KiivuVideoThumbnail from './video_thumbnail';
// import VideoThumbnail from 'react-video-thumbnail';



const Devices = () => {
  const {signedIn, klogin, klogout} = useKiivuAuth();
  const isAuthenticated = useIsAuthenticated();
  const [fileInfo, setFileInfo] = useState(null);
  const [videoMetadata, setVideoMetadata] = useState({});
  const [url, setUrl] = useState();

  const videoRef = useRef(null);

  useEffect(() => {
    if(isAuthenticated())
      klogin();
   else
      klogout();
    
  }, []);


  const handleChange = (event)=>{
    const file = event.target.files[0];
    if (file) {
      const fileDetails = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: new Date(file.lastModified), // Convert to Date object
      };
      console.log(file.lastModified);
      // Set up the video element to load the video
      const url = URL.createObjectURL(file);
      console.log(url)
      setUrl(url);
      const videoElement = videoRef.current;
      videoElement.src = url;
      videoElement.onloadedmetadata = () => {
        setVideoMetadata({
          duration: videoElement.duration, // Duration in seconds
          width: videoElement.videoWidth, // Width in pixels
          height: videoElement.videoHeight // Height in pixels
        });
        URL.revokeObjectURL(url); // Clean up the object URL
      };

      setFileInfo(fileDetails);
    }
  }

  const handleThumbnail = (thumbnail)=>{
    console.log(thumbnail)
  }

  const [thumbnail, setThumbnail] = useState(null);
  const inputRef = useRef(null);

  // useEffect(()=>{
  //    console.log(url)
  // },[url])



  const handleChange2 = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement("video");

      const handleLoadedData = () => {
        if (snapImage(video)) {
          video.removeEventListener("loadeddata", handleLoadedData);
        }
      };

      const handleTimeUpdate = () => {
        if (snapImage(video)) {
          video.removeEventListener("timeupdate", handleTimeUpdate);
          video.pause();
        }
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      video.play();
    };

    fileReader.readAsArrayBuffer(file);
  };

  const handleFile=(file)=>{
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const blob = new Blob([fileReader.result], { type: file.type });
      const url = URL.createObjectURL(blob);
      const video = document.createElement("video");

      const handleLoadedData = () => {
        if (snapImage(video)) {
          video.removeEventListener("loadeddata", handleLoadedData);
        }
      };

      const handleTimeUpdate = () => {
        if (snapImage(video)) {
          video.removeEventListener("timeupdate", handleTimeUpdate);
          video.pause();
        }
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.src = url;
      video.muted = true;
      video.playsInline = true;
      video.play();
    };

    fileReader.readAsArrayBuffer(file);
  }


  const snapImage = (video) => {
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL();
    const success = image.length > 100000;

    if (success) {
      setThumbnail(image); // Update state with new thumbnail
      // Clean up
      URL.revokeObjectURL(video.src);
    }

    return success;
  };


  return (
    <div>
      Devices
      {/* <input type='file' onChange={handleChange2}></input>
      <video
        ref={videoRef}
        style={{ display: 'none' }} // Hide the video element
      /> */}
     {/* <div>......</div> */}
     {/* <div className='w-1/2'>
     <video src={vd1} width="750" height="500" controls download={false} controlslist="nodownload"/>
     </div> 
     {/* <KiivuVrPlayer src={''} type={'video/mp4'}/> */}
     {/* <div>......</div> */}
     {/* <img src='/kiivu-default-thumbnail.jpg' alt=''/> */}
     

     <input
        ref={inputRef}
        type="file"
        accept=".mp4"
        onChange={handleChange2}
      />
      {thumbnail && (
        <div>
          <h2>Generated Thumbnail:</h2>
          <img src={thumbnail} alt="Thumbnail" />
        </div>
      )}


    {/* <div className=''>
    <VideoThumbnail
        videoUrl={url}
        thumbnailHandler={(thumbnail) => handleThumbnail(thumbnail)}
        width={1920}
        height={1080}
        snapshotAtTime={2}
        />
    </div> */}
        {/* <KiivuVideoThumbnail/> */}
        {/* {fileInfo && (
        <div>
          <h3>File Details:</h3>
          <p><strong>File Name:</strong> {fileInfo.name}</p>
          <p><strong>File Size:</strong> {fileInfo.size} bytes</p>
          <p><strong>File Type:</strong> {fileInfo.type}</p>
          <p><strong>Last Modified:</strong> {fileInfo.lastModifiedDate.toString()}</p>
        </div>
      )}
      {Object.keys(videoMetadata).length > 0 && (
        <div>
          <h3>Video Metadata:</h3>
          <p><strong>Duration:</strong> {videoMetadata.duration.toFixed(2)} seconds</p>
          <p><strong>Width:</strong> {videoMetadata.width} pixels</p>
          <p><strong>Height:</strong> {videoMetadata.height} pixels</p>
        </div>
      )} */}
    </div>
  )
}

export default Devices
