

export const loginUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/token';
export const logoutUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/oauth/revoke';


export const client_id = "t7usWb-2mYbqg-7t23Zqbcc7v6Q2JODmD7WE9juCj8g";
export const client_secret = "BMmtx9gZahvrETIVhXBvr3Us_8-8fGspfssE3Cr7dqM";
export const create_mediaUrl = 'https://staging-kiivu-v1-5b9f9fe098c3.herokuapp.com/api/v1/standard/media';

export const s3_endpointUrl = 'https://s3.us-east-1.wasabisys.com';



const AuthRequestBody = (values) =>{return {
    "grant_type":"password",
    "client_id":client_id,
    "client_secret":client_secret,
    "email":values.email,
    "password":values.password
  };
}

export default AuthRequestBody;