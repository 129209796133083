import { createContext, useContext, useState,useEffect } from "react";


export const KiivuAuthContext = createContext();

export const KiivuAuthProvider = ({children})=>{

    const [signedIn, setSignIn] = useState(false);
    // const [signedOut, setSignedOut] = useState(true);
    
    useEffect(()=>{
       const signed = localStorage.getItem('kiivu');
       if(signed){
         setSignIn(true);
       }
    },[]);
    
    const klogin = () =>{
      setSignIn(true);
      localStorage.setItem('kiivu', true);
    }

    const klogout = ()=>{
        setSignIn(false);
        localStorage.removeItem('kiivu');
    }

    return(
        <KiivuAuthContext.Provider value={{signedIn, klogin, klogout}}>
           {children}
        </KiivuAuthContext.Provider>
    );
}

export const useKiivuAuth = ()=>{
    return useContext(KiivuAuthContext);
}
