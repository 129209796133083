import React,{useState, useEffect, useRef} from 'react';
import { useAuthHeader } from 'react-auth-kit';
import axios from 'axios';
import VideoItem from '../components/video_item';
import { create_mediaUrl } from '../config/authcredential';
import DropdownEditMenu from '../components/dropdown_edit_menu';
import { useLibrary } from '../context/LibraryContext';
import {useIsAuthenticated,} from 'react-auth-kit';
import { useKiivuAuth } from '../context/KiivuAuthProvider';
import './library.css';
import TableView from '../components/table_view';
import { useDashboard } from '../context/DashboardContext';


// const sampleData = Array.from({ length: 100 }, (_, index) => ({
//   id: index + 1,
//   name: `Item ${index + 1}`,
//   description: `Description for item ${index + 1}`,
// }));


const Library = () => {

  const authHeader = useAuthHeader();
  const token = authHeader();
  const [medias, setMedias] = useState([]);
  const {setMetaData} = useDashboard();
  const [isSortOpen, setIsSortOpen] = useState(false);
  const {libraryVideos, setLibraryVideos} = useLibrary();
  const btnRef = useRef()
  const {signedIn, klogin, klogout} = useKiivuAuth();
  const isAuthenticated = useIsAuthenticated();


  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 10;
  // const totalPages = Math.ceil(sampleData.length / itemsPerPage);

  const toggleDropdown = () => {
    setIsSortOpen(!isSortOpen);
  };

  const closeDropdown = (event) => {
    event.preventDefault();
    setIsSortOpen(false);
  };

  const headers = {
    // 'Content-Type': 'application/json', // Specify content type JSON
     'Authorization': token, // Example Authorization header
   };
   const getMedia = async()=>{
    await axios.get(create_mediaUrl ,{headers : headers})
              .then(response =>{
                 const temp = response.data;
                //  temp.reverse();
                //  console.log(temp);
                // setMedias(temp['media']); 
                 setLibraryVideos(temp['media']);
                 setMetaData(temp['meta']);
              })
              .catch(error=> console.error(error));
   
 }
  
//  const getFormattedDate = (dateString)=>{
//   const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = date.getMonth() + 1; // getMonth() returns zero-based index
//   const day = date.getDate();
//   return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
//  }
 
//  const randomNumber =() =>{
//   return Math.floor(Math.random() * (7 - 1) + 1);
// }

  useEffect(()=>{
    // console.log(token);
    getMedia();}, []);

    useEffect(() => {
      const closeDropdown = e => {
          if (!btnRef.current.contains(e.target)) {
            setIsSortOpen(false);
          }
      }
      document.body.addEventListener('click', closeDropdown)
      return () => document.body.removeEventListener('click', closeDropdown)
  }, [])

    useEffect(() => {
      if(isAuthenticated())
        klogin();
     else
        klogout();
      
    }, []);  

  const limitTextat = (text, limit)=>{
     if(text.length <= limit) return text;
     else{
        let temp = text.substring(0, 10) + '...';
        return temp;
     }
  }


  const fileSizeFromByte = (bytes)=>{
    const bytesToMB = () => (bytes / (1024 * 1024)).toFixed(2);
    const bytesToGB = () => (bytes / (1024 * 1024 * 1024)).toFixed(2);
    const sizeInGB = bytesToGB();
    const sizeInMB = bytesToMB();
    if(sizeInGB >= 1)
      return sizeInGB + ' GB'
    else{
      return sizeInMB + ' MB'
    }
   }
  
   const timeAgo = (timestamp) => {
    const now = new Date();
    const then = new Date(timestamp);
    const diffInSeconds = Math.floor((now - then) / 1000);
  
    if (diffInSeconds < 60) {
      return 'just now';
    }
  
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    }
  
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    }
  
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 365) {
      return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    }
  
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
  };



  return (
    <>
    <div className='flex justify-between items-center mb-4 mx-4'>
      <h3 className="text-xl font-bold">All Videos</h3>
       {/* <div className='relative flex flex-row'> */}
       <div className="relative z-0">
      <button 
        ref={btnRef}
        className="px-4 py-2 text-gray-800 rounded-md focus:outline-none"
        onClick={toggleDropdown}
      >
        Sort by{' '}
        <svg
          className={`w-4 h-4 inline-block ml-1 -mr-1 ${isSortOpen ? 'transform rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
        </svg>
      </button>
      {isSortOpen && (
        <div className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-0">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Alphabetical (A-Z)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Alphabetical (Z-A)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Date (Newest)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>Date (Oldest)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>File Size (Low to High)</a>
            <a href="" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={closeDropdown}>File Size (High to Low)</a>
          </div>
        </div>
      )}
    </div>
      </div>
     {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 px-4'>
        {medias.length > 0 && (
          medias.map((m, index)=> (
            <VideoItem key={index} name={m.name} description={m.description}/>
          ))
        )}
      </div> */}
      <div>
      <table className="table-auto w-full border border-gray-200">
      <thead>
            <tr className="w-full bg-gray-100 border-b">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
              <th className="min-w-8 min-h-4 hidden sm:table-cell"></th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-96 hidden xl:table-cell">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Size</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Uploaded</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Menu</th>
            </tr>
          </thead>
          <tbody>
          {libraryVideos.length > 0 &&  (libraryVideos.map((item, index) => (
          <tr key={item.id} className={`${
            index % 2 === 0 ? 'bg-white' : 'bg-gray-200'
          }`}>
            {/* <th><input type='checkbox' className='mr-1'></input>{item.id}</th> */}
            <td className='px-6 py-3 text-left'>{index+1}</td>
            <td className='px-6 py-4 text-left whitespace-nowrap min-w-12 max-w-xs hidden sm:table-cell ' ><img className='aspect-video w-40' src={item.artwork_cdn_url} alt=''/></td>
            <td className='px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900  max-w-xs truncate' >{limitTextat(item.name, 20)}</td>
            <td className='px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 w-96  hidden xl:table-cell  max-w-xs truncate'>{item.description}</td>
            {/* <td className='hidden sm:table-cell'>{item.size}</td> */}
            <td className='px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell'>{fileSizeFromByte(item.file_size)}</td>
            <td className='px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell min-w-fit'>{timeAgo(item.created_at)}</td>
            <td className='text-right'>
              {/* <button className=' active:scale-110 transition-transform duration-200 ease-in-out' id="menu-button" aria-expanded="true" aria-haspopup="true">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
              </svg>
              </button> */}
              <DropdownEditMenu name={item.name} description={item.description} className='w-14'  id={item.id} refresh={getMedia}/>
            </td>
          </tr>
        )))}
      
            {/* <tr>
              <th>1</th>
              <td>name1</td>
              <td>Malcolm Lockyer</td>
              <td>1GB</td>
              <td>2024/07/04</td>
              <th>...</th>
            </tr>
            <tr>
              <th>2</th>
              <td>name2</td>
              <td>The Eagles</td>
              <td>2GB</td>
              <td>2024/07/04</td>
              <th>...</th>
            </tr>
            <tr>
              <th>3</th>
              <td>name3</td>
              <td>Earth, Wind, and Fire</td>
              <td>4GB</td> 
              <td>2024/07/04</td>
              <th>...</th>
            </tr> */}
          </tbody>
        </table>

       {/* <div>
          <input type='file' onChange={handleFileChange}/>
       </div> */}
      {/* {libraryVideos.length > 0 && (<TableView medias={libraryVideos} refresh={getMedia}/>)} */}

      </div>
    </>
  )
}

export default Library




