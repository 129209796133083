import React, { useState, useRef, useEffect } from 'react';

const DropdownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const btnRef = useRef()
  
  useEffect(() => {
        const closeDropdown = e => {
            if (!btnRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    }, [])

  return (
    <div ref={btnRef} className="relative inline-block text-left bg-transparent">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center items-center outline-none gap-x-1.5  px-3 py-2 text-sm font-semibold text-gray-900 ring-gray-300"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
         <div className='rounded-full border-none'>
             {/* <img src={profileImage} className='rounded-full sm:size-8 size-6' alt=''/> */}
             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-black">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
            </svg>
         </div>
         <div className='sm:block hidden'>
          {props.name}
         </div>
          {/* Why  */}
          {/* <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
          </svg> */}
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1 px-1 rounded-sm" role="none">
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-200" role="menuitem" tabIndex="-1" id="menu-item-0">
              Account settings
            </a>
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-200" role="menuitem" tabIndex="-1" id="menu-item-1">
              Profile
            </a>
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-200" role="menuitem" tabIndex="-1" id="menu-item-2">
              Contact Us
            </a>
            {/* <form method="POST" action="#" role="none"> */}
              <button
                type="submit"
                className="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-slate-200"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-3"
                onClick={props.callback}
              >
                Sign out
              </button>
            {/* </form> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
