import React from 'react'
import profileImage from '../assets/bg0.jpeg';

const AssetCard = () => {
  
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg hover:scale-105 active:scale-100  bg-transparent cursor-pointer">
    <img className="w-full aspect-square rounded-xl" src={profileImage} alt="video"/>
    <div className="px-6 py-4 flex flex-col justify-start items-start ">
        <div className="font-bold text-xl mb-2">Video name</div>
        <div className="font-bold text-xl mb-2">2.6GB</div>
        {/* <p className="text-gray-700 text-base">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
        </p> */}
    </div>
   
    </div>
  )
}

export default AssetCard