// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truncate-text {
    max-lines: 2;
    max-width: 100%; /* Ensure the container does not overflow its parent */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/video_item.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe,EAAE,sDAAsD;IACvE,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".truncate-text {\n    max-lines: 2;\n    max-width: 100%; /* Ensure the container does not overflow its parent */\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
