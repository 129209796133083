import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useIsAuthenticated } from 'react-auth-kit'


const SidebarMenu = () => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(()=>{

  },[]);


  return (
   isAuthenticated() ?  <div className="w-full h-screen mx-auto">
   <aside className="w-full h-screen text-white" aria-label="Sidebar">
	   <div className="h-full px-3 py-4 overflow-y-auto rounded bg-gray-950 dark:bg-gray-800">
		   <ul className="space-y-5 text-white">
			   <li className=' font-extrabold'>KIIVU</li>
			   <div className='h-5'></div>
			   <li>
				   <Link to={'/dashboard'}>
				   <div 
					   className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
					   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
					   <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
					   </svg>

					   <span className="flex-1 ml-3 whitespace-nowrap text-white hover:text-black active:text-gray-400">Dashboard</span>
				   </div>
				   </Link>
			   </li>
			   
			   <li>
				   <Link to={'/library'}>
				   <div href="#"
					   className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
					   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
					   <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
					   </svg>

					   <span className="flex-1 ml-3 whitespace-nowrap text-white hover:text-black active:text-gray-400">Library</span>
				   </div>
				   </Link>
			   </li>
			   <li>
				<Link to={'/devices'}>
				   <div href="#"
					   className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
					   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
					   <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
					   </svg>

					   <span className="flex-1 ml-3 whitespace-nowrap text-white hover:text-black active:text-gray-400">Devices</span>
				   </div>
				   </Link>
			   </li>
			   
		   </ul>
	   </div>
   </aside>
   </div> : <></>
  )
}

export default SidebarMenu