import React, {useEffect, useState} from 'react'
import { useAuthHeader } from 'react-auth-kit'
import axios from 'axios';

const FileUploader = (props) => {
  
 const [uploadProgress, setUploadProgress] = useState();

//  const config = {
//     headers: {
//       'Authorization': token,
//       'Content-Type': 'multipart/form-data', // Required for file uploads
//     },
//     onUploadProgress: (progressEvent) => {
//       const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
//       setUploadProgress(progress);
//     },
//   };
// const handleFileUpload = async () => {
        
//   try {
//     const response = await axios.post(props.url, props.formData, {
//       headers: {
//         'Authorization': props.token,
//         'Content-Type': 'multipart/form-data',
//       },
//       onUploadProgress: (progressEvent) => {
//         const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//         setUploadProgress(progress);
//       },
//     });

//     console.log('File uploaded successfully:', response.data);
//     // Handle success (e.g., show success message)
//   } catch (error) {
//     console.error('Error uploading file:', error);
//     // Handle error (e.g., show error message)
//   }
// };
// handleFileUpload();

 const progress = 50;

 useEffect(()=>{
    const handleFileUpload = async () => {
        
        try {
          const response = await axios.post(props.url, props.formData, {
            headers: {
              'Authorization': props.token,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(progress);
            },
          });
    
          console.log('File uploaded successfully:', response.data);
          // Handle success (e.g., show success message)
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle error (e.g., show error message)
        }
      };
   //  handleFileUpload();
 },[]);



  return (
    <div>
        <div className="bg-gray-100 text-black flex flex-row justify-between items-center">
         <div className='flex-1'>{props.name}</div>
         <div className="h-4 relative w-full rounded-full overflow-hidden bg-gray-200">
          <div className="bg-black absolute left-0 top-0 h-full" style={{ width: '33%' }}></div>
        </div>
{/* 
          <div className="progress" style={{ width: `${uploadProgress}%` }}>
            {uploadProgress}%
          </div> */}
        </div>
    </div>
  )
}

export default FileUploader