import React, { useState } from 'react';
import DropdownEditMenu from './dropdown_edit_menu';

// Sample data for the table
const sampleData = Array.from({ length: 100 }, (_, index) => ({
  id: index + 1,
  name: `Item ${index + 1}`,
  description: `Description for item ${index + 1}`,
  uploadDate:'2024/07/01'
}));

const TableView = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
//   const {libraryVideos, Set}
  const itemsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(sampleData.length / itemsPerPage);

  // Calculate the data to display on the current page
  const currentItems = sampleData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getFormattedDate = (dateString)=>{
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns zero-based index
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
   }


  return (
    <div className="h-auto">
      <div className="">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="w-full bg-gray-100 border-b">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Size</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Date</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Menu</th>
            </tr>
          </thead>
          <tbody>
            {props.medias.map((item,index )=> (
              <tr key={index} className="border-b">
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{item.id}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell">{item.description}</td>
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell">1GB</td>
                <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900 hidden sm:table-cell">{getFormattedDate(item.created_at)}</td>
                <td className="text-right"><DropdownEditMenu name={item.name} description={item.description} className='w-14'  id={item.id} refresh={props.refresh}/></td>
                {/* <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{item.description}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 text-white bg-blue-500 rounded-lg disabled:opacity-50"
        >
          Previous
        </button>
        <div className="text-sm text-gray-700">
          Page {currentPage} of {totalPages}
        </div>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 text-white bg-blue-500 rounded-lg disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableView;
