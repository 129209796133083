import { createContext, useContext, useState,useEffect } from "react";


export const LibraryContext = createContext();

export const LibraryProvider = ({children})=>{

    
  const [libraryVideos, setLibraryVideos] = useState([]);
    useEffect(()=>{
      
    },[]);
    
    return(
        <LibraryContext.Provider value={{libraryVideos, setLibraryVideos}}>
           {children}
        </LibraryContext.Provider>
    );
}

export const useLibrary = ()=>{
    return useContext(LibraryContext);
}
