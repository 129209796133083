import React, { forwardRef,useRef, useState, useImperativeHandle } from 'react';

const KiivuDropzone = forwardRef((props, ref) => {
    const [dragging, setDragging] = useState(false);
    const internalRef = useRef();
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');

    // Handle drag enter event
    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    // Handle drag over event
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    // Handle drag leave event
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    };

    // Handle drop event
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFiles(files);
        }
    };

    useImperativeHandle(ref, () => ({
        triggerFunction() {
        //   alert('Function triggered from parent!');
         handleReset()
        },
      }));
     
      const handleReset = () => {
        if (fileInputRef.current) {
          // fileInputRef.current.value = '';
            // Create a temporary form to reset the file input
            const form = document.createElement('form');
            fileInputRef.current.parentNode.insertBefore(form, fileInputRef.current);
            form.appendChild(fileInputRef.current);
            form.reset();
            form.parentNode.insertBefore(fileInputRef.current, form);
            form.remove(); // Clean up the temporary form
        }
    };


    // Handle files from input or drop
    const handleFiles = (files) => {
        if (files.length > 0) {
            if (files.length > 1) {
                alert('You can only select one file at a time.');
                return;
            }

            const file = files[0];
            if (file && file.type.startsWith('video/')) {
                // Update the file input with the dropped files
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                if (fileInputRef.current) {
                    fileInputRef.current.files = dataTransfer.files;
                }

                setFileName(file.name);
            } else {
                alert('Please drop a video file.');
            }
        }
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        handleFiles(files);
        props.handleInputFile(event);
    };

    

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className='mx-5'>
            <div
                onClick={handleClick}
                style={{
                    border: `2px dashed ${dragging ? 'blue' : 'gray'}`,
                    padding: '40px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
                className='rounded-md'
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {dragging ? 'Drop the file here...' : 'Click or drag a file here to upload'}
            </div>
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="video/*" // Ensure only video files can be selected
            />
            {/* {fileName && <p>Selected file: {fileName}</p>} */}
        </div>
    );
});

export default KiivuDropzone;